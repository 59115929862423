<template>
  <section>
    <hero-global title="Aviso Legal" />
  </section>
  <div class="container p-1-rem p-top-0-px">
    <section>
      <p class="subtitle">Aviso Legal</p>
      <article>
        <p>Con la finalidad de dar cumplimiento al artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico, informamos al usuario de nuestros datos: cermi.es es un dominio en internet de titularidad de CERMI (Comité Español de Representantes de Personas con Discapacidad), con NIF: G-816 39 759, C/. Recoletos, 1 Bajo, 28001 Madrid - 91 360 16 78, España. CERMI, como asociación de ámbito estatal declarada de utilidad pública, está inscrita en el Registro Nacional de Asociaciones del Ministerio del Interior con el número: 162.062.

        </p>
        <p>La información contenida en esta web ha sido elaborada por CERMI.
        </p>
        <p>El acceso al sitio web de CERMI, así como el uso que pueda hacerse de la información que contiene, son de la exclusiva responsabilidad de la persona usuaria. CERMI no se responsabilizará de ninguna consecuencia, daño o perjuicio que pudieran derivarse del uso de este sitio web o de sus contenidos, incluidos daños informáticos y la introducción de virus, con excepción de todas aquellas actuaciones que resulten de la aplicación de las disposiciones legales a las que deba someterse el estricto ejercicio de sus competencias.
        </p>
        <p>CERMI desarrollará los esfuerzos precisos para evitar errores y, en su caso, repararlos o actualizarlos lo antes posible, no pudiendo garantizar su inexistencia, ni que el contenido de la información se encuentre permanentemente actualizado. Si encuentra algún error, por favor notifíquelo a <a class="text-primary text-decoration-none text-bold" href="mailto:cermi@cermi.es.">cermi@cermi.es.</a></p>
        <p>
          La información que se difunde por este medio se hace únicamente a título informativo, reservándose CERMI el derecho de eliminar o suspender su difusión, total o parcialmente, y de modificar la estructura y contenido de este sitio web sin aviso previo, pudiendo incluso limitar o no permitir el acceso a dicha información.

        </p>
        <p>
          El propósito de CERMI es mantener la calidad y actualización de esta información y evitar y minimizar posibles errores causados por fallos técnicos. Sin embargo, no garantiza que este servicio no sea interrumpido o afectado por esos fallos.

        </p>
        <p>
          CERMI no se hace responsable de las opiniones de terceras personas que hayan sido publicadas en medios de comunicación, y que puedan ser difundidas a través del portal web. Se prohíbe la reproducción total o parcial de los contenidos de esta WEB sin citar su origen y solicitar autorización expresa de la entidad.

        </p>
      </article>
    </section>
  </div>
  <br />
</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";

  export default {
    name: "LegalWarning",
    components: { HeroGlobal }
  }
</script>

<style scoped>

</style>